// state/store.js
import { createStore } from 'vuex';
import modules from './modules';
import immagine1day from '@/assets/images/slider/viale 2-4.jpg';
import immagine2day from '@/assets/images/slider/2.webp';
import immagine3day from '@/assets/images/slider/3.webp';
import immagine1night from '@/assets/images/slider/1_n.webp';
import immagine2night from '@/assets/images/slider/2_n.webp';
import immagine3night from '@/assets/images/slider/3_n.webp';
  
export default createStore({
  modules,
  state: {
    isDarkMode: false,
    images: {
      day: [
        { id: 1, url: immagine1day },
        { id: 2, url: immagine2day },
        { id: 3, url: immagine3day },
      ],
      night: [
        { id: 1, url: immagine1night },
        { id: 2, url: immagine2night },
        { id: 3, url: immagine3night },
      ]
    }
  },
  getters: {
    currentImages(state) {
      return state.isDarkMode ? state.images.night : state.images.day;
    }
  },
  mutations: {
    toggleDarkMode(state) {
      state.isDarkMode = !state.isDarkMode;
    }
  }
});