<template>
  <div :class="{ 'apply-margin-left': $route.meta.authRequired === 'FullLogin' }">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
.apply-margin-left {
  margin-left: var(--vertical-menu-width-sm);
}
</style>