export default [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: { authRequired: "NoLogin" },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("../views/account/forgot-password.vue"),
        meta: { authRequired: "NoLogin" },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: { authRequired: "NoLogin" },
        component: () => import('../views/auth/create/reset')
    },
    {
        path: '/twostep',
        name: 'twostep-basic',
        meta: { authRequired: "PartialLogin" },
        component: () => import('../views/auth/twostep/basic')
    },
    {
        path: '/unsubscribe',
        name: 'unsubscribe',
        component: () => import('../views/account/unsubscribe')
    },
    {
        path: "/immobile-nuovo",
        name: "immobile-nuovo",
        meta: { authRequired: "FullLogin" },
        component: () => import("../views/apps/projects/immobile-nuovo"),
    },
    {
        path: "/immobile-lista-admin",
        name: "immobile-lista-admin",
        meta: { authRequired: "FullLogin" },
        component: () => import("../views/apps/projects/immobile-lista-admin"),
    },
    {
        path: "/immobili",
        name: "immobili",
        component: () => import("../views/apps/projects/immobile-lista"),
        props: (route) => ({ query: route.query })
    },
    {
        path: "/immobile-statistiche",
        name: "immobile-statistiche",
        meta: { authRequired: "FullLogin" },
        component: () => import("../views/apps/projects/immobile-statistiche"),
    },
    {
        path: "/chi-siamo",
        name: "chi-siamo",
        component: () => import("../views/apps/projects/chi-siamo"),
    },
    {
        path: "/nostri-servizi",
        name: "nostri-servizi",
        component: () => import("../views/apps/projects/nostri-servizi"),
    },
    {
        path: "/noi-nello-sport",
        name: "noi-nello-sport",
        component: () => import("../views/apps/projects/noi-nello-sport"),
    },
    {
        path: "/contatto",
        name: "contatto",
        component: () => import("../views/apps/projects/contatto"),
    },
    {
        path: "/home",
        name: "home",
        component: () => import("../views/apps/projects/immobile-homepage"),
    },
    {
        path: "/dettagli",
        name: "dettagli",
        component: () => import("../views/apps/projects/immobile-dettagli"),
    },
    {
        path: "/immobile-a4",
        name: "immobile-a4",
        component: () => import("../views/apps/projects/immobile-a4"),
    },
    {
        path: "/immobile-a3",
        name: "immobile-a3",
        component: () => import("../views/apps/projects/immobile-a3"),
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("../views/apps/projects/privacy-policy"),
    },

    {
        path: "/:catchAll(.*)",
        redirect: "/home"
    }
];